<template>
    <div class="root">
        <div class="containerWrapper">
            <div class="outerContainerHead">
                <web class="webIcon" />
                <span>{{ $t("application.outerContainerTitle") }}</span>
                <span class="nextRandomCountdown" v-if="suspend == 2">{{ $t("application.nextRandomCountdownTitle") }}{{ currentCountdown }}
                    S</span>
                <span class="nextRandomCountdown" v-if="suspend == 1 && yuyan == 'zh'">{{ suspend_zh }}</span>
                <span class="nextRandomCountdown" v-if="suspend == 1 && yuyan == 'en'">{{ suspend_en }}</span>
            </div>
            <div class="outerContainer">
                <div class="topCard card">
                    <div class="title">
                        <setting class="settingIcon" />
                        <span>{{ $t("application.setting") }}</span>
                    </div>
                    <!-- end of title -->

                    <div class="formInputs">
                        <!-- form inputs. -->
                        <div class="inputContainer">
                            <span class="inputTitle">{{ $t("application.startRangeTips") }}</span>
                            <input type="text" v-model="form.n" />
                        </div>
                        <!--  -->
                        <div class="inputContainer">
                            <span class="inputTitle">{{ $t("application.endRangeTips") }}</span>
                            <input type="text" v-model="form.m" />
                        </div>
                        <!--  -->
                        <div class="inputContainer">
                            <span class="inputTitle">{{ $t("application.selectionTips") }}</span>
                            <input type="text" v-model="form.x" />
                        </div>
                    </div>
                    <!-- end of form inputs -->
                </div>
                <div class="pulseIndex">
                    <span v-if="queryResult.pulse_index">
                        PULSE_INDEX:{{ queryResult.pulse_index }}
                    </span>
                </div>
                <div v-loading="loading" class="bottomCard card">
                    <div class="resultContainer">{{ queryResult.random_number }}</div>
                </div>
                <!-- bottom buttons. -->
                <div class="bottomButtons">
                    <div @click="query" class="inlineButton">
                        <random />
                        <span>{{ $t("application.randomButtonText") }}</span>
                    </div>
                    <div @click="copyResult" class="inlineButton">
                        <copy />
                        <span>{{ $t("application.copyButtonText") }}</span>
                    </div>
                    <div @click="downloadResult" class="inlineButton">
                        <download />
                        <span>{{ $t("application.downloadButtonText") }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import web from "../svg/web.vue";
import setting from "../svg/setting.vue";
// button-inline-icon random,copy and download
import random from "../svg/random.vue";
import copy from "../svg/copy.vue";
import download from "../svg/download.vue";

export default {
    components: {
        web,
        setting,
        random,
        copy,
        download,

    },
    data() {
        return {
            form: {
                // startRange
                n: "",
                // endRange
                m: "",
                // selectionRange
                x: "",
            },
            queryResult: {},
            loading: false,
            pulseIndex: 0,
            currentCountdown: 0,
            suspend: 2,
            suspend_zh:"",
            suspend_en: "",
            yuyan: localStorage.getItem("locale"),
        };
    },
    watch: {
        "$i18n.locale"(newValue) {
            if (this.$i18n.locale === "zh") {
                this.yuyan = "zh";
            } else {
                this.yuyan = "en";
            }
        },
    },
    async created() {
        if (!this.yuyan) {
            this.yuyan = "zh";
        }
        const countdownRes = await this.$post("index/random/gettime");
        this.currentCountdown = countdownRes.data;
        setInterval(() => {
            if (--this.currentCountdown <= 0) {
                this.currentCountdown = 60;
            }
        }, 1000);
        this.getConfig();
    },
    methods: {
        getRandom(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min);
        },
        query() {
            if (this.loading) {
                return;
            }

            const n = Number(this.form.n);
            const m = Number(this.form.m);
            const x = Number(this.form.x);

            function isValidNumber(value) {
                const v = Number(value);
                return !!value && !isNaN(v) && v > 0;
            }

            if (!isValidNumber(n) || !isValidNumber(m) || !isValidNumber(x)) {
                this.$message.error(this.$t(`application.inputIllegalTips`));
                return;
            }

            this.loading = true;
            this.$post("index/random/example", this.form)
                .then((res) => {
                    if (res.code != 0) {
                        this.$message.error(res.msg);
                        return;
                    }
                    this.queryResult = res.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        downloadResult() {
            if (!this.queryResult.random_number) {
                return;
            }
            const blob = new Blob([this.queryResult.random_number], {
                type: "text/plain;charset=utf-8",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = this.$t("application.downloadFileName");
            a.click();
            window.URL.revokeObjectURL(url);
        },
        async copyResult() {
            if (!this.queryResult.random_number) return;
            await navigator.clipboard.writeText(this.queryResult.random_number);
            this.$message.success(this.$t(`application.copySuccessTips`));
        },
        getConfig(){
            this.$post("/index/random/getConfig", "").then((res) => {
                if(res.data.suspend == 1){
                    this.suspend = 1;
                    this.suspend_zh = res.data.suspend_zh;
                    this.suspend_en = res.data.suspend_en;
                }
            });
        }
    },
};
</script>
  
<style scoped>
.root {
    background-color: white;
    width: 100%;
}

.containerWrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
}

.outerContainerHead {
    width: 100%;
    background-color: var(--page-container-background-color);
    box-sizing: border-box;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 2px solid var(--page-border-color);
    border-bottom: none;
}

.webIcon,
.settingIcon {
    height: 30px;
    width: 30px;
}

.outerContainerHead>span {
    margin-left: 5px;
    color: var(--page-font-color);
}

.nextRandomCountdown {
    margin-left: 10px !important;
    color: red !important;
}

.outerContainer {
    border: 2px solid var(--page-border-color);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.card {
    border-radius: 5px;
    border: 2px solid var(--page-border-color);
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
}

.title {
    box-sizing: border-box;
    color: var(--page-font-color);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.title>span {
    margin-left: 5px;
}

.formInputs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px 0;
    margin-top: 15px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 25%;
    gap: 5px;
}

.inputTitle {
    color: var(--page-font-color);
    font-size: 15px;
}

.inputContainer>input {
    width: 100%;
    height: 40px;
    border: 2px solid var(--page-border-color);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0 10px;
    outline: none;
    font-size: 16px;
    color: var(--page-font-color);
}

.pulseIndex {
    width: 100%;
    margin: 15px 0;
    color: var(--page-font-color);
    text-align: left;
    font-weight: bold;
}

.bottomCard {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.resultContainer {
    font-weight: bold;
    font-size: 18px;
    color: var(--page-font-color);
    min-height: 70px;
    text-align: left;
    word-break: break-all;
}

.bottomButtons {
    width: 100%;
    padding: 15px 0;
    border: 2px solid var(--page-border-color);
    border-top: none;
    box-sizing: border-box;
    background-color: var(--page-container-background-color);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inlineButton {
    border: 2px solid #587fe9;
    box-sizing: border-box;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    width: min-content;
    white-space: nowrap;
    margin: 0 10px;
    user-select: none;
    cursor: pointer;
    border-radius: 5px;
}

.inlineButton>svg {
    height: auto;
    width: 25px;
}

.inlineButton>span {
    margin-left: 5px;
    color: #587fe9;
}
</style>
  