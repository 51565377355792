<template>
  <div class="root">
    <lunbo></lunbo>
    <div class="tabsContainer">
      <el-tabs @tab-click="handleTabClick" type="border-card">
        <el-tab-pane>
          <span slot="label">
            <div class="tabItem">
              <web />
              <span>{{ $t('application.acquireTitle') }}</span>
            </div>
          </span>
          <!-- page-component -->
          <acquire />
        </el-tab-pane>
        <el-tab-pane>
          <span slot="label">
            <div class="tabItem">
              <random />
              <span>{{ $t('application.downloadTitle') }}</span>
            </div>
          </span>
          <!-- page-component -->
          <download />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import acquire from '../components/applicationPageComponents/acquire.vue';
import download from '../components/applicationPageComponents/download.vue';

// svg
import web from '../components/svg/web.vue';
import random from '../components/svg/random.vue';


export default {
  name: 'application',
  components: {
    acquire,
    download,
    // svg
    web,
    random
  },
  methods: {
    handleTabClick(e) {
      const index = Number(e.index);
      if (index == 1 && !this.$cookie.get("userName")) {
        this.$message.error(this.$t("application.loginTips"));
        setTimeout(() => {
          this.$router.push({ path: "/login", query: { do: "login" } })
        }, 1800);
      }
    }
  }
}
</script>

<style>
.tabsContainer {
  box-sizing: border-box;
  padding: 15px;
}

.tabItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  color: #587fe9;
}

.el-tabs {
  box-sizing: border-box;
  border: 2px solid #d5e0ff !important;
}

.tabItem>svg {
  height: 20px;
  width: 20px;
}

.el-tabs__header {
  background-color: #d5e0ff !important;
  border: none !important;
}
</style>