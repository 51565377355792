<template>
    <div class="root">
        <div class="downloadTipsRow">
            <span class="downloadTips">{{ $t('application.downloadTips', { downloadCountRemain }) }}</span>
            <div v-loading="downloadButtonLoading" @click="downloadRandom" class="inlineButton">
                <downloadSvg />
                <span>{{ $t("application.downloadButtonText") }}</span>
            </div>
        </div>
        <!-- history-list -->
        <div class="historyListContainer">
            <div v-if="downloadHistoryList.length" class="downloadHistoryTitle">
                <clock />
                <span>{{ $t("application.historyListTitle") }}</span>
            </div>
            <div v-if="downloadHistoryList.length" class="historyList">
                <div v-for="(item, index) in downloadHistoryList" :key="index" class="historyItem">
                    <span class="createTime">{{ $t('application.randomGenerateTime') }} : {{ item.addtime }}</span>
                    <div @click="downloadCurrentFile(item.url)" class="inlineButton">
                        <span style="margin-left: 0;">{{ $t("application.downloadButtonText") }}</span>
                    </div>
                </div>
            </div>
            <div v-else class="emptyText">{{ $t("application.noHistoryTips") }}</div>
        </div>
        <div class="paginationContainer">
            <el-pagination background :page-size="pagination.limit" :total="totalCount"
                @current-change="handlePageChange"></el-pagination>
        </div>
    </div>
</template>

<script>

// SVG
import copy from "../svg/copy.vue";
import download from "../svg/download.vue"
import clock from "../svg/clock.vue"

export default {
    name: "download",
    components: {
        copy,
        downloadSvg: download,
        clock
    },
    data() {
        return {
            downloadCountRemain: 0,
            downloadHistoryList: [],
            pagination: {
                limit: 10,
                page: 1
            },
            totalCount: 0,
            downloadButtonLoading: false
        }
    },
    created() {
        this.flushHistory();
        this.flushDownloadCount();
    },
    methods: {
        handlePageChange(page) {
            this.pagination.page = page;
            this.flushHistory();
        },
        downloadCurrentFile(url) {
            const aElement = document.createElement("a");
            aElement.href = url;
            aElement.click();
        },
        flushHistory() {
            this.$post('index/qrng_download/list', {
                ...this.pagination
            }).then(({ data }) => {
                this.downloadHistoryList = data.data;
                this.totalCount = data.total;
            });
        },
        flushDownloadCount() {
            this.$post('index/qrng_download/getnum').then(({ data }) => {
                this.downloadCountRemain = data;
            });
        },
        async downloadRandom() {
            this.downloadButtonLoading = true;
            const downloadRes = await this.$post("index/qrng_download/add");
            this.downloadButtonLoading = false;
            if (downloadRes.code == 0) {
                const aElement = document.createElement("a");
                aElement.href = downloadRes.data.url;
                aElement.click();
            }else{
                this.$message.error(downloadRes.msg);
            }
            this.flushDownloadCount();
            this.flushHistory();
        }
    }
}
</script>

<style scoped>
.root {
    width: 100%;
}

.downloadTipsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    width: 100%;
}

.downloadTips {
    color: var(--page-font-color);
    font-size: 15px;
}

.inlineButton {
    border: 2px solid #587fe9;
    box-sizing: border-box;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    width: min-content;
    white-space: nowrap;
    margin: 0 10px;
    user-select: none;
    cursor: pointer;
    border-radius: 5px;
}

.inlineButton>svg {
    height: auto;
    width: 25px;
}

.inlineButton>span {
    margin-left: 5px;
    color: #587fe9;
}

.historyListContainer {
    width: 100%;
    border-radius: 5px;
    border: 2px solid var(--page-border-color);
    box-sizing: border-box;
    padding: 10px 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.downloadHistoryTitle {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 15px;
    color: var(--page-font-color);
    gap: 5px;
}

.historyItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    color: var(--page-font-color);
    font-size: 15px;
    border-bottom: 1px solid var(--page-border-color);
}

.historyItem:last-child {
    border-bottom: none;
}

.paginationContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--page-border-color);
    padding: 50px 0;
}
</style>